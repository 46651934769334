var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"nav py-0",attrs:{"fixed":"","flat":"","hide-on-scroll":"","height":"80px"}},[_c('v-menu',{attrs:{"bottom":"","open-on-hover":"","tile":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-toolbar-title',_vm._g(_vm._b({staticClass:"mt-2 mt-lg-0"},'v-toolbar-title',attrs,false),on),[_c('router-link',{staticClass:"nav__brand text-uppercase align-center mt-0 mt-lg-2",attrs:{"to":{ name: 'Home' }}},[_c('v-img',{attrs:{"width":"44","height":_vm.site == 'creators' ? '48' : '44',"src":_vm.site == 'creators' ? _vm.creatorsLogo : _vm.standardLogo,"laz-src":_vm.site == 'creators' ? _vm.creatorsLogoLazy : _vm.standardLogoLazy}}),_c('span',{staticClass:"align-center ml-2 mt-1 d-none d-sm-flex"},[_vm._v(_vm._s(_vm.title))])],1)],1)]}}])},[_c('v-card',{staticClass:"pa-0 hidden-sm-and-down",attrs:{"tile":"","flat":"","color":"white","width":"320px"}},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"nav__button text-uppercase justify-start",attrs:{"text":"","tile":"","height":"72"},on:{"click":_vm.changeSite}},[_c('v-img',{attrs:{"max-width":"36","max-height":"40","src":_vm.site == 'creators' ? _vm.standardLogo : _vm.creatorsLogo,"laz-src":_vm.site == 'creators' ? _vm.standardLogoLazy : _vm.creatorsLogoLazy}}),_c('span',{staticClass:"align-center mx-auto"},[_vm._v(_vm._s(_vm.site == "creators" ? _vm.standardTitle : _vm.creatorsTitle))])],1)],1)],1)],1)],1)],1),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"nav__links hidden-md-and-down"},_vm._l((_vm.pages),function(page){return _c('router-link',{key:page.index,staticClass:"nav__link text-uppercase py-6 px-6",class:_vm.site == 'creators' ? 'nav__link--creators' : 'nav__link--standard',attrs:{"to":{
        name: page.name
      }}},[_vm._v(" "+_vm._s(page.name)+" ")])}),1),_c('v-menu',{staticClass:"hidden-lg-and-up",attrs:{"bottom":"","tile":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({staticClass:"hidden-lg-and-up"},'v-app-bar-nav-icon',attrs,false),on))]}}])},[_c('v-card',[_c('v-list',{staticClass:"pa-0"},[_vm._l((_vm.pages),function(page){return _c('v-list-item',{key:page.index,staticClass:"pa-0"},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('router-link',{staticClass:"nav__link text-uppercase py-7 px-6",class:_vm.site == 'creators'
                  ? 'nav__link--creators'
                  : 'nav__link--standard',attrs:{"to":{
                name: page.name
              }}},[_vm._v(" "+_vm._s(page.name)+" ")])],1)],1)}),_c('v-list-item',[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"nav__button text-uppercase justify-start pa-0",attrs:{"text":"","tile":"","height":"72"},on:{"click":_vm.changeSite}},[_c('v-img',{attrs:{"max-width":"36","max-height":"40","src":_vm.site == 'creators' ? _vm.standardLogo : _vm.creatorsLogo,"laz-src":_vm.site == 'creators' ? _vm.standardLogoLazy : _vm.creatorsLogoLazy}}),_c('span',{staticClass:"align-center mx-auto"},[_vm._v(_vm._s(_vm.site == "creators" ? _vm.standardTitle : _vm.creatorsTitle))])],1)],1)],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }