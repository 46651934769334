var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app"},[(
      _vm.site == 'creators' ||
        _vm.$route.name == 'Landing' ||
        _vm.$route.name == 'FourOhFour'
    )?_c('CreatorsParticlesJS',{staticClass:"app--content1"}):_c('StandardParticlesJS',{staticClass:"app--content1"}),_c('v-app',{staticClass:"app--content2",class:_vm.$route.name == 'Landing' || _vm.$route.name == 'FourOhFour'
        ? 'app__landing'
        : _vm.site == 'creators'
        ? 'app__creators'
        : _vm.$route.name == 'Home'
        ? 'app__standard--home'
        : 'app__standard'},[(_vm.$route.name == 'Landing')?_c('div',{staticClass:"mt-4 py-16"},[_c('router-view',{key:_vm.$route.path})],1):_c('div',[_c('Navigation'),_c('v-main',{staticClass:"pb-16"},[_c('div',{staticClass:"mt-4 py-16"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view',{key:_vm.$route.path})],1)],1)]),_c('Footer',{staticClass:"app__footer",attrs:{"site":_vm.site}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }